// import * as dotenv from 'dotenv';
import process from 'process';
import React, { useState, useEffect, useRef } from 'react';
import styles from "./index.module.scss";
import { Input, Space, Form, Button, Select, DatePicker, Divider, Tabs, Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import axios from "axios";
import moment from 'moment-timezone';
import { get, post } from '../../util/io'
import PlanA from './planA';
import PlanB from './planB';
import PlanC from './planC';
import PlanD from './planD';
import StickyBox from 'react-sticky-box';
import { string } from 'mathjs';
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ArrowRightOutlined } from '@ant-design/icons';
import liff from "@line/liff";


const Product = ({defaultKey}) => {
  const navigate = useNavigate();
  // liff.sendMessages([{ type: "text", text: "localStorage.getItem" }]);
  let userAgentString = navigator.userAgent;
  let isLIFF = userAgentString.indexOf("LIFF") > -1;

  const Operations = () => {
    return (
      <Button
        type="primary"
        icon={<ArrowRightOutlined />}
        onClick={() => {navigate('/signup')}}
      >
        加入會員
      </Button>
    )
  }
  const menuItems = [
    {
      label: '查看其他方案',
      icon: <MenuOutlined />,
      children: [
        { label: 'A. 全園區套票', key: 'planA' },
        // { label: 'B. 影音學習套票', key: 'planB' },
        // { label: 'C. 筆記精華套票', key: 'planC' },
        // { label: 'D. 小資成長套票', key: 'planD' },
      ],
    },
  ];
  const [current, setCurrent] = useState('planA');
  const handleMenuClick = (e) => {
    setCurrent(e.key);
  };
  
return (
  
  <div className={styles.main}>
      <div className={styles.tabContainer}>
        <div className={styles.menuContainer}>
          <Menu mode="horizontal" items={menuItems} onClick={handleMenuClick} selectedKeys={[current]} style={{fontWeight: '700' }} />
        </div>
        <div className={styles.operationsContainer}>
          <Operations />
        </div>
      </div>
      {current === 'planA' && <PlanA />}
      {/* {current === 'planB' && <PlanB />} */}
      {/* {current === 'planC' && <PlanC />} */}
      {/* {current === 'planD' && <PlanD />} */}
    <footer>
    <p>
      技術支援 &copy; 2023 &nbsp;
      <a href="https://toriiitech.pse.is/co-wrote" target="_blank" rel="noopener noreferrer" style={{ color: "#24936E", textalign: "left", fontWeight: 400  }}>
          鳥居科技 Toriii
      </a>
    </p>
    </footer>
    </div>

  );
};

export default Product;