// import * as dotenv from 'dotenv';
import process from 'process';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import styles from "./index.module.scss";
import { Input, Space, Form, Button, Select, Radio, Divider, Skeleton, Modal, Card} from 'antd';
import { CopyOutlined, ExclamationCircleFilled, CheckCircleOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { get, post } from '../../util/io';
import liff from "@line/liff";
import qrcodeImage from './QRcode_.png';

const price = ["230","168", "188"];
const defaultCurrentUser = {
  lineUserId: undefined,
  discountType: 0,
}

const SignUp = () => {
  const userProfile = JSON.parse(localStorage.getItem("userProfile"));
  let userAgentString = navigator.userAgent;
  let isLIFF = userAgentString.indexOf("LIFF") > -1;
  const [ userData, setUserData ] = useState(undefined);
  const [ withInputData, setWithInputData ] = useState(false);
  const [ currentUser, setCurrentUser ] = useState(defaultCurrentUser);
  const [ inputData, setInputData ] = useState({});
  const [ plan, setPlan ] = useState("A. 全園區套票"); 
  const [ loading, setLoading ] = useState(false);
  const header = {
    'ngrok-skip-browser-warning': 'true',
    password: process.env.REACT_APP_API_PASSWORD,
  };

  const onSetPlan = (e) => {
    setPlan(e.target.value);
  }

  const copyText = () => {
    // Copy the text inside the text field
    navigator.clipboard.writeText("TRhiWxjSZwj2iiessNy8YRV2XWbdzpPVAV");
    window.toast.success('TRC 地址已複製');
  }

  const howmuch = () => {
    const boldTextStyle = {
      fontWeight: 'bold',
    };
  
    switch (plan) {
      case "A. 全園區套票":
        return (
          <p>
            提幣 {price[currentUser.discountType]}U 至以下 <span style={boldTextStyle}>TRC</span> 地址並『截圖』紀錄，匯款前請先扣除扣除手續費
          </p>
        );
      // case "B. 影音學習套票":
      //   return (
      //     <p>
      //       提幣 150U 至以下 <span style={boldTextStyle}>TRC</span> 地址並『截圖』紀錄，匯款前請先扣除扣除手續費
      //     </p>
      //   );
      // case "C. 筆記精華套票":
      //   return (
      //     <p>
      //       提幣 125U 至以下 <span style={boldTextStyle}>TRC</span> 地址並『截圖』紀錄，匯款前請先扣除扣除手續費
      //     </p>
      //   );
      // case "D. 小資成長套票":
      //   return (
      //     <p>
      //       提幣 50U 至以下 <span style={boldTextStyle}>TRC</span> 地址並『截圖』紀錄，匯款前請先扣除扣除手續費
      //     </p>
      //   );
      default:
        return (
          <p>
            提幣 230U 至以下 <span style={boldTextStyle}>TRC</span> 地址
          </p>
        );
    }
  };
  

  const done = () => {
    setInputData({
      lineId: userProfile.userId,
      lineUserName: userProfile.displayName,
      linePFP: userProfile.pictureUrl,
      plan: plan,
    });
    // console.log({
    //   lineId: userProfile.userId,
    //   lineUserName: userProfile.displayName,
    //   linePFP: userProfile.pictureUrl,
    //   plan: plan,
    // });
    setWithInputData(true);
    setLoading(true);
  }

  if (userData === undefined) {
    get(`${process.env.REACT_APP_API_URL}/brucelinda/vip/allMember`, header)
    .then((resp)=>{
      setUserData(resp.data.data);
    })
    .catch((errorMsg)=>{
      console.log(errorMsg);
    });
  }

  useEffect(() => {
    if (userData !== undefined) {
      const result = userData.find(element => element.lineUserId === userProfile.userId);
      if (result) {
        setCurrentUser({
          lineUserId: result.lineUserId,
          discountType: result.discountType,
        });
      } else {
        setCurrentUser({
          lineUserId: userProfile.userId,
          discountType: 0,
        });
      }
    }
  }, [userData]);
  
  useEffect(() => {
    let header = {
      'ngrok-skip-browser-warning': 'true',
      password: process.env.REACT_APP_API_PASSWORD,
    };
    if (withInputData) {
      console.log('inputData: ', inputData);
      post(`${process.env.REACT_APP_API_URL}/brucelinda/vip/signUp`, header, inputData)
      .then((resp) => {
        setLoading(false);
        let status = resp.data.status;
        console.log(status);
        if (status === 'success') {
          liff.sendMessages([
            {
              type: "text",
              text: "加入申請",
            },
          ])
          .then(() => {
            liff.closeWindow();
          });
          console.log("Good");
        } else {
          window.toast.error('系統錯誤，請稍候再試');
        }
      })
      .catch((errorMsg)=>{
        console.log(errorMsg);
      })
    }
  }, [inputData, withInputData]);
  const CustomRadio = styled(Radio.Button)`
  &.ant-radio-button-wrapper-checked {
    background-color: #D76B63 !important;
    border-color: #D76B63 !important;
    color: white !important;
  }
  `;
  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <h2>申請加入</h2>
        { isLIFF ? null : <p style={{ fontSize: '12px', color: 'white' }}>請使用line開啟此頁</p>}
      </div>
      <div className={styles.formContainer}>
        <>
          <h4>入群方案</h4>
          <Card>
            <p>
            🔴 A. 全園區套票：
            {price[currentUser.discountType]}U / 一個月
            {/* <br/><br/>
            🔴 B. 影音學習套票：
            150U / 一個月
            <br/><br/>
            🔴 C. 筆記精華套票：
            125U / 一個月
            <br/><br/>
            🔴 D. 小資成長套票：
            50U / 一個月 */}
            </p>
          </Card>
          <Divider/>
          <h4>付費流程</h4>
          <p>1️⃣ 請點選方案</p>
          <Radio.Group value={plan} onChange={onSetPlan} buttonStyle="default" style={{ width: "100%" }}>
            <CustomRadio value="A. 全園區套票">
              A. 全園區套票
            </CustomRadio>
          </Radio.Group>
          {/* <Radio.Group value={plan} onChange={onSetPlan} buttonStyle="default" style={{ width: "100%" }}>
            <CustomRadio value="C. 筆記精華套票">
              C. 筆記精華套票
            </CustomRadio>
          </Radio.Group> */}
          {/* <Radio.Group value={plan} onChange={onSetPlan} buttonStyle="default" style={{ width: "100%" }}>
            <CustomRadio value="D. 小資成長套票">
              D. 小資成長套票
            </CustomRadio>
          </Radio.Group> */}
          <p><br/>2️⃣ {howmuch()}</p>
          <p style={{ color: "#D76B63" }}>*截圖內需包含交易 ID 或 Hash 值，請確認交易所已放行提幣成功後再截圖</p>
          <Button key="copyText" type="default" onClick={copyText} style={{ fontWeight: 500 }}>
            TRhiWxjSZwj2iiessNy8YRV2XWbdzpPVAV
            {/* {<CopyOutlined/>} */}
          </Button>
          <img src={qrcodeImage} alt="" />
          <p><br/>3️⃣ 點擊以下『我已轉帳』按鈕，並於聊天室內附上提幣紀錄圖，審核通過後管理員會立即將您加入社群</p>
          <p style={{ color: "#D76B63" }}>*截圖內需包含交易 ID 或 Hash 值，請確認交易所已放行提幣成功後再截圖</p>
          <Button key="done" type="primary" onClick={done} disabled={loading} size='large'>
            我已轉帳 {<CheckCircleOutlined />}
          </Button>
        </>
      </div>
      <footer>
      <p>
        技術支援 &copy; 2023 &nbsp;
        <a href="https://toriiitech.pse.is/co-wrote" target="_blank" rel="noopener noreferrer" style={{ color: "#24936E" }}>
           鳥居科技 Toriii
        </a>
      </p>
      </footer>
    </div>
  );
};

export default SignUp;
// module.exports = Calculator;