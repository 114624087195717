// import * as dotenv from 'dotenv';
import process from 'process';
import React, { useState, useEffect, useRef } from 'react';
import styles from "./index.module.scss";
import { Input, Space, Form, Button, Select, DatePicker, Divider, Skeleton, Radio} from 'antd';
import axios from "axios";
import moment from 'moment-timezone';
import { get, post } from '../../../util/io'

const PlanA = () => {
  const [ withData, setWithData ] = useState(false);
  const header = {
    password: process.env.REACT_APP_API_PASSWORD,
  };

  if (withData === false) {
    get(`${process.env.REACT_APP_API_URL}/copyTrade/performance/demo`, header)
    .then((resp)=>{
      setWithData(true);
    })
    .catch((errorMsg)=>{
      console.log(errorMsg);
    })
  }
  
  return (
    <div className={styles.container}>
      <div className={styles.chartContainer}>
        <h2 style={{ paddingTop: '12px' }}>A. 全園區套票</h2>
        <div className={styles.space}>
          <div className={styles.performance}>
            <h5>方案介紹</h5>
            <p>方案月費：$ 230 U <br></br> YouTube 會員月費：NT 9600</p>
          </div>
          <Divider style={{ margin: '12px 0px' }}/>
          <div className={styles.performance}>
            <h5>方案內容</h5>
            <p>⚫️ 筆記精華<br></br> ⚫️ 歷史影音、教學視聽<br></br> ⚫️ 交易訓練室、心得檢討、園長叮嚀、晨報廣播<br></br> ⚫️ 強勢標的、交易閒聊、交易貼布、娛樂專區</p>
          </div>
          <Divider style={{ margin: '12px 0px' }}/>
        </div>
        <Button ghost type="primary" href='https://bubuco.gitbook.io/document/vip-hui-yuan-jie-shao/fang-an-jie-shao'>
          查看詳細介紹
        </Button>
      </div>
    </div>

  );
};

export default PlanA;